import {
    paymentWays,
    ItemTransactionPaymentType,
    invoiceValidationTypes,
}
from '@/libs/acl/Lookups';

export default {
    data() {
        return {
            // lookups entites
            paymentWays: paymentWays,
            itemTransactionTypes: ItemTransactionPaymentType,
            currentType: 'sales',
            invoiceValidationTypes,
            // lookup object
            lookups: {
                customers: [],
                agents: [],
                taxLinks: [],
                stores: [],
                taxes: [],
                safes: [],
            },
            // invoice Model
            selectedItem: {
                code: '',
                isOffline: false,
                transactionSource: 'web',
                transactionDate: '',
                notes: '',
                isPosted: false,
                postedVoucherId: null,
                transactionType: 'sales',
                transactionChannel: 'normal',
                sourceTransactionId: null,
                sourceTransactionType: null,
                paymentType: null,
                priceListId: null,
                dealerId: null,
                onTheFlyDealerName: '',
                agentId: null,
                storeId: null,
                driverId: null,
                carId: null,
                projectId: null,
                total: 0,
                discountTypeId: null,
                secretCode: null,
                discountPercentage: 0,
                discountValue: 0,
                netBeforeTaxes: 0,
                untaxableDiscountValue: 0,
                untaxableDiscountPercentage: 0,
                ensureGoodWorksExecutionDiscountValue: 0,
                ensureGoodWorksExecutionDiscountPercentage: 0,
                totalTaxes: 0,
                net: 0,
                isComplete: false,
                lines: [],
                taxes: [],
                payments: [],
                customerProjects: [],
                projects: [],
                cementTypes: [],
                cars: [],
                castingTypes: [],
                pumpDrivers: [],
                mixerDrivers: [],
                assistantDrivers: [],
                costCenters: []
            },
        };
    },
    computed: {
        status() {
            if (this.selectedItem.isComplete) return this.$t('complete');
            if (this.selectedItem.isPosted) return this.$t('posted');
            if (this.selectedItem.lines.some((i) => i.consumedQuantity)) return this.$t('usedInAnother');
            return null;
        },
        isClosed() {
            // return !!this.status;
            return (
                this.selectedItem.isPosted || this.selectedItem.isComplete
                || this.selectedItem.lines.some((i) => i.consumedQuantity)
            );
        },
        hasSource() {
            return !!this.selectedItem.sourceTransactionId;
        },
        isEditable() {
            return this.selectedItem.id || this.selectedItem.sourceTransactionId;
        },
        companyTax() {
            return [
                {
                    taxTypeArabicName: this.$t('companyTax'),
                    taxTypeEnglishName: this.$t('companyTax'),
                    taxTypeId: '',
                    taxPercentage: this.company.taxPercentage,
                    taxValue: 0,
                },
            ];
        },
        isLineBaseDiscount() {
            return this.currentBranch.discountLevel === 'items';
        },
        tableColumns() {
            return [
                {
                    key: 'paymentMethod',
                    label: this.$t('paymentType'),
                    isLocale: true,
                    sortable: false,
                },
                {
                    key: this.isRight
                        ? 'otherPaymentMethodArabicName'
                        : 'otherPaymentMethodEnglishName',
                    label: this.$t('safe'),
                    sortable: false,
                    formatter: (key, value, item) => {
                        if (item.safeId) return this.isRight ? item.safeArabicName : item.safeEnglishName;
                        return this.isRight
                            ? item.otherPaymentMethodArabicName
                            : item.otherPaymentMethodEnglishName;
                    },
                },
                {
                    key: 'totalValue',
                    label: this.$t('value'),
                    sortable: false,
                    formatter: (key, value, item) => {
                        if (item.paymentMethod === 'other') {
                            return this.fraction(item.value)
                        } if (item.paymentMethod === 'safe') {
                            return this.fraction(item.value)
                        }
                    },
                },
                {
                    key: 'cashPaid',
                    label: this.$t('paid'),
                    sortable: false,
                    formatter: (key, value, item) => {
                        if (item.paymentMethod === 'other') {
                            return this.fraction(item.value)
                        } if (item.paymentMethod === 'safe') {
                            return this.fraction(item.cashPaid)
                        }
                    },
                },
                {
                    key: 'remaining',
                    label: this.$t('remaindered'),
                    sortable: false,
                    formatter: (key, value, item) => {
                        if (item.paymentMethod === 'other') {
                            return 0
                        } if (item.paymentMethod === 'safe') {
                            return this.fraction(item.cashPaid) - this.fraction(item.value)
                        }
                    },
                    footer: () => this.fraction(this.remaindered),
                    tdClass: this.$route.name !== 'pos-invoice' || this.$route.name !== 'pos-invoice-edit' ? '' : 'd-none',
                    thClass: this.$route.name !== 'pos-invoice' || this.$route.name !== 'pos-invoice-edit' ? '' : 'd-none',
                },
                { key: 'actions' },
            ];
        },
    },
    // watch: {
    //   'selectedItem.dealerId'(newVal) {
    //     if (this.currentBranch.activityType === 'concrete' && newVal) {
    //       // const res = this.lookups.customers.find((v) => v.id === newVal);
    //       this.get({ url: `Customers/${newVal}/projects` }).then((data) => {
    //         const projectsIds = data.map((b) => b.id);
    //         this.customerProjects = this.projects.filter(
    //           (b) => projectsIds.indexOf(b.id) > -1
    //         );
    //       });
    //     }
    //   }
    // },
    mounted() {
        const { query } = this.$route;
        Object.keys(query)
            .filter((key) => this.selectedItem[key] !== undefined)
            .forEach((key) => {
                this.selectedItem[key] = query[key];
            });
        if (this.currentBranch.activityType === 'concrete') {
            this.getProjects();
            this.getDrivers();
        }
        // if (this.$route.name === 'pos-invoice' || this.$route.name === 'pos-invoice-edit') {
        //   this.currentType = 'sales';
        // } else if (this.$route.name === 'pos-quotation' || this.$route.name === 'pos-quotation-edit') {
        //   this.currentType = 'quotation';
        // } else if (this.$route.name === 'sales-order-new' || this.$route.name === 'sales-order-edit') {
        //   this.currentType = 'salesOrder';
        // } else if (this.$route.name === 'return-sales-new' || this.$route.name === 'return-sales-edit') {
        //   this.currentType = 'returnSales';
        // }
    },
    methods: {
        computeTotalDiscount() {
            // if (!this.isLineBaseDiscount && this.selectedItem.discountPercentage) {
            //   this.selectedItem.discountValue = (parseFloat(this.total) * this.selectedItem.discountPercentage) / 100;
            // }
            // compute discount for item based
            if (this.isLineBaseDiscount) {
                let itemTotal = 0;
                let itemDiscount = 0;
                this.selectedItem.lines.forEach((item) => {
                    itemDiscount += parseFloat(item.discountValue);
                    itemTotal += this.fraction(item.total);
                });
                this.selectedItem.total = itemTotal;
                this.selectedItem.discountValue = itemDiscount;
                this.selectedItem.discountPercentage = this.fraction(
                    (itemDiscount / itemTotal) * 100
                );
            }
        },
        computeTotalTaxes() {
            let isNotTaxAble = false;
            const customer = this.lookups.customers.find(x => x.id === this.selectedItem.dealerId);
            if (customer && !customer.isTaxable) {
                isNotTaxAble = true;
            }
            if (this.currentBranch.taxPolicy === 'netBased') {
                this.companyTax.forEach((tax) => {
                    tax.value = isNotTaxAble ? 0 : this.fraction(this.selectedItem.netBeforeTaxes) * this.fraction(Number(tax.taxPercentage) / 100);
                    this.selectedItem.totalTaxes += this.fraction(tax.value);
                });
                this.selectedItem.net = this.fraction(this.selectedItem.netBeforeTaxes) + this.fraction(this.selectedItem.totalTaxes);
            } else if (this.currentBranch.taxPolicy === 'priceIncluded') {
                // get taxes from item
                this.companyTax.forEach((tax) => {
                    tax.value = this.selectedItem.lines.reduce((taxTotal, item) => {
                        const price = this.fraction(item.net) / this.fraction(1 + (this.fraction(tax.taxPercentage) / 100));
                        taxTotal += this.fraction(price) * this.fraction(this.fraction(tax.taxPercentage) / 100);
                        return isNotTaxAble ? 0 : this.fraction(taxTotal);
                    }, 0);
                    this.selectedItem.totalTaxes += this.fraction(tax.value);
                });
                this.selectedItem.netBeforeTaxes -= this.fraction(this.selectedItem.totalTaxes);
            } else if (this.currentBranch.taxPolicy === 'itemBased') {
                this.companyTax.forEach((tax) => {
                    tax.value = this.selectedItem.lines.reduce((taxTotal, item) => {
                        if (!item.isTaxable) return this.fraction(taxTotal);
                        var itemDiscount = 0;
                        var totalDisc = 0;
                        var itemNet = 0;
                        if (this.currentBranch.discountLevel !== 'items') {
                            // totalDisc = this.selectedItem.discountPercentage / 100
                            totalDisc = (this.selectedItem.discountValue / this.selectedItem.total) * 100
                            itemDiscount = item.net * (totalDisc / 100);
                            itemNet = item.net - itemDiscount;
                            taxTotal += this.fraction(itemNet) * this.fraction(tax.taxPercentage / 100);
                        } else if (this.currentBranch.discountLevel === 'items') {
                            // itemDiscount = item.net * (this.fraction(item.discountPercentage) / 100);
                            itemNet = this.fraction(item.net);
                            taxTotal += this.fraction(itemNet) * this.fraction(this.fraction(tax.taxPercentage) / 100);
                        }
                        return isNotTaxAble ? 0 : this.fraction(taxTotal);
                    }, 0);
                    this.selectedItem.totalTaxes += this.fraction(tax.value);
                });
                this.selectedItem.net = this.selectedItem.netBeforeTaxes + this.selectedItem.totalTaxes;
            }
            this.selectedItem.totalTaxes = isNotTaxAble ? 0 : this.fraction(this.selectedItem.totalTaxes);
        },
        getSelecteItemObj() {
            this.selectedItem = {
                code: '',
                isOffline: false,
                transactionSource: 'web',
                transactionDate: '',
                notes: '',
                isPosted: false,
                postedVoucherId: null,
                transactionType: 'sales',
                transactionChannel: this.$route.name !== 'restaurant-menu' ? 'normal' : 'resturant',
                sourceTransactionId: null,
                sourceTransactionType: null,
                paymentType: this.$route.name !== 'restaurant-menu' ? null : 'cash',
                priceListId: null,
                dealerId: null,
                onTheFlyDealerName: '',
                agentId: null,
                storeId: null,
                driverId: null,
                carId: null,
                projectId: null,
                total: 0,
                discountPercentage: 0,
                discountValue: 0,
                untaxableDiscountValue: 0,
                untaxableDiscountPercentage: 0,
                netBeforeTaxes: 0,
                totalTaxes: 0,
                net: 0,
                isComplete: false,
                lines: [],
                taxes: [],
                payments: [],
                customerProjects: [],
                projects: [],
                cementTypes: [],
                cars: [],
                castingTypes: [],
                maxTemperatures: [],
                pumpDrivers: [],
                mixerDrivers: [],
                assistantDrivers: [],
                costCenters: []
            };
            this.setupDefualts();
            // this.getAppliedTaxes();
        },
        getModel() {
            const model = JSON.parse(JSON.stringify(this.selectedItem));
            // remove unneeded properities from model items
            model.lines = model.lines.map((item) => {
                return {
                    transactionId: model.id !== 0 ? item.transactionId : 0,
                    lineSerial: item.lineSerial,
                    storeId: item.storeId,
                    itemId: item.itemId,
                    unitId: item.unitId,
                    unitConversionRate: item.unitConversionRate,
                    quantity: item.quantity || 0,
                    price: this.fraction(item.price) || 0,
                    total: this.fraction(item.total) || 0,
                    additions: item.additions,
                    costPrice: item.costPrice,
                    serial: item.serial || null,
                    carColorId: item.carColorId || null,
                    carColorName: item.carColorName || null,
                    carStatus: item.carStatus || null,
                    cementTypeId: item.cementTypeId || null,
                    discountPercentage: item.discountPercentage || this.fraction(0),
                    discountValue: item.discountValue || 0,
                    net: item.net || 0,
                    notes: item.notes,
                };
            });

            // remove unneeded properities from model payments
            model.payments = model.payments.map((item) => {
                return {
                    transactionId: item.transactionId || 0,
                    lineSerial: item.lineSerial,
                    value: this.fraction(item.value),
                    cashPaid: this.fraction(item.cashPaid),
                    paymentMethod: item.paymentMethod,
                    safeId: item.safeId,
                    paymentDate: item.paymentDate,
                    otherPaymentMethodId: item.otherPaymentMethodId,
                    referenceNumber: item.referenceNumber,
                };
            });

            model.taxes = model.taxes.map((item) => {
                return {
                    transactionId: model.id || 0,
                    taxTypeId: item.taxTypeId,
                    taxPercentage: item.taxPercentage,
                    taxValue: item.taxValue,
                };
            });
            return model;
        },
        getAppliedTaxes() {
            const customer = this.lookups.customers.find(x => x.id === this.selectedItem.dealerId);
            // compute applied taxes
            if (this.id > 0) return;
            if (this.currentBranch.taxPolicy === 'netBased') {
                var taxVal = 0
                this.selectedItem.taxes = this.lookups.taxLinks.map((tax) => {
                    if (customer && !customer.isTaxable) {
                        taxVal = 0
                    } else {
                        taxVal = tax.taxType.taxPercentage
                    }
                    return {
                        taxTypeArabicName: tax.taxType.arabicName,
                        taxTypeEnglishName: tax.taxType.englishName,
                        taxTypeId: tax.taxType.id,
                        taxPercentage: tax.taxType.taxPercentage,
                        taxValue: 0,
                        taxVal: taxVal
                    };
                });
            } else this.selectedItem.taxes = [];
        },
        preparePaymentModel() {
            this.paymentMethod.lineSerial = this.selectedItem.payments.length + 1;
            this.paymentMethod.safeArabicName = this.safeTransactions.safeArabicName;
            this.paymentMethod.safeEnglishName = this.safeTransactions.safeEnglishName;
            this.paymentMethod.safeId = null;
            this.paymentMethod.otherPaymentMethodId = this.paymentMethod.otherPaymentMethodId || null;
            if (this.payemntType === 'Safes') {
                this.paymentMethod.paymentMethod = 'safe';
                this.paymentMethod.safeId = this.safeTransactions.safeId || null;
            } else {
                this.paymentMethod.paymentMethod = 'other';
            }
        },
        saveAsDraft() {
            if (this.selectedItem.customerId && this.selectedItem.storeId && this.selectedItem.lines.length > 0) {
                // Add
            }
        },
        getProjects() {
            this.get({ url: 'Projects' }).then((data) => {
                this.projects = data;
            }).then(() => {
                this.get({ url: 'CastingTypes' }).then((data) => {
                    this.castingTypes = data;
                })
            }).then(() => {
                this.get({ url: 'CementTypes' }).then((data) => {
                    this.cementTypes = data;
                })
            })
                .then(() => {
                    this.get({ url: 'Cars' }).then((data) => {
                        this.cars = data;
                    })
                })
                .then(() => {
                    this.get({ url: 'MaxTemperatures' }).then((data) => {
                        this.maxTemperatures = data;
                    })
                })
                .catch(() => {
                    this.projects = [];
                })
        },
        getDrivers() {
            this.get({ url: 'Drivers?Type=pumpDriver' }).then((data) => {
                this.pumpDrivers = data;
            }).catch(() => {
                this.pumpDrivers = [];
            })
            this.get({ url: 'Drivers?Type=mixerDriver' }).then((data) => {
                this.mixerDrivers = data;
            }).catch(() => {
                this.mixerDrivers = [];
            })
            this.get({ url: 'Drivers?Type=assistant' }).then((data) => {
                this.assistantDrivers = data;
            }).catch(() => {
                this.assistantDrivers = [];
            })
        },
        setupDefualts() {
            this.selectedItem.transactionDate = this.today;
            this.selectedItem.paymentType = 'cash';
            if (this.transactionType === 'salesOrder' && this.currentBranch.activityType === 'concrete') {
                this.selectedItem.paymentType = 'credit'
            }
            if (this.$route.name === 'contracts-new' || this.$route.name === 'contracts-edit') {
                this.selectedItem.paymentType = 'credit'
            }
            this.selectedItem.transactionTime = this.getTime();
            this.selectedItem.storeId = this.currentBranch.defaultStoreId || null;
            if (this.$route.name !== 'restaurant-menu') {
                if (this.$route.query.dealerId) {
                    this.selectedItem.dealerId = Number(this.$route.query.dealerId)
                } else {
                    this.selectedItem.dealerId = this.selectedItem.dealerId || this.currentBranch.defaultCustomerId || null;
                    // if (this.currentBranch.activityType === 'concrete' && this.selectedItem.dealerId) {
                    //   // const res = this.lookups.customers.find((v) => v.id === this.selectedItem.dealerId);
                    //   this.get({ url: `Customers/${this.selectedItem.dealerId}/projects` }).then((data) => {
                    //     const projectsIds = data.map((b) => b.id);
                    //     this.customerProjects = this.projects.filter(
                    //       (b) => projectsIds.indexOf(b.id) > -1
                    //     );
                    //   });
                    // }
                    // this.customerProjects = res.projects
                }
            } else {
                this.selectedItem.dealerId = null;
            }
            if (this.$route.name !== 'restaurant-menu') {
               // this.changeCustomer(this.lookups.customers.find((item) => item.id === this.selectedItem.dealerId) || {});
            }
            this.selectedItem.discountPercentage = this.customer.discount;
            if (this.currentBranch.enablePriceLists === true) {
                this.selectedItem.priceListId = this.currentBranch.defaultPriceListId || null;
            } else {
                this.selectedItem.priceListId = null;
            }
        },
        beforeSaveValidation() {
            if (this.selectedItem.discountPercentage > this.lookups.currentUser.maxAllowedDiscount
                && this.customer.discount < this.selectedItem.discountPercentage) {
                // alert here
                this.doneAlert({ text: this.$t('DiscountValueIsMoreThanLimit'), type: 'error' });
                return false;
            }
            return true;
        },
        beforeSaveInvTypeValidation() {
            if (this.selectedItem.paymentType === 'cash' && this.customer.cashSales === false) {
                // alert here
                this.doneAlert({ text: this.$t('notAllowPaymentTypeForCustomer'), type: 'error' });
                return false;
            }
            if (this.selectedItem.paymentType === 'credit' && this.customer.creditSales === false) {
                // alert here
                this.doneAlert({ text: this.$t('notAllowPaymentTypeForCustomer'), type: 'error' });
                return false;
            }
            return true;
        },
        beforeSaveValidationDealer() {
            if (!this.selectedItem.dealerId) {
                // alert here
                this.doneAlert({ text: this.$t('youShoudSelectCustomer'), type: 'error' });
                return false;
            }
            return true;
        },
        updateViewModel(taxType = 'sales') {
            if (this.transactionType !== 'exchangeOrder') {
                this.transType = this.transactionType === 'contract' ? 'salesOrder' : this.transactionType;
            } else {
                this.transType = '';
            }
            if (this.transactionType === 'concreteDelivery') {
                this.transType = 'sales'
            }
            var url = this.profile.isAdmin === false ? `Aggregates/salesViewModel?userId=${this.profile.userId}&isClosed=false&transactionType=${taxType}&dealerType=${this.transType}&agentId=${this.profile.agentId !== null ? this.profile.agentId : ''}` : `Aggregates/salesViewModel?userId=${this.profile.userId}&isClosed=false&transactionType=${taxType}&dealerType=${this.transType}`;
            this.get({
                url: url,
            }).then((data) => {
                this.lookups = data;
                // data.customers.forEach(element => {
                //     const phone = element.phone ? `- ( ${element.phone} )` : ''
                //     element.arabicName = `${element.code} - ${element.arabicName} ${phone}`;
                //     element.englishName = `${element.code} - ${element.englishName} ${phone}`;
                // });
                this.handelLookups();
                if (!this.id && !this.selectedItem.sourceTransactionId) this.setupDefualts();
            })
        },
        handelLookups() {
            if (this.currentBranch.stores && this.currentBranch.stores.length > 0) {
                const branchStores = this.currentBranch.stores.map(
                    (item) => item.storeId
                );
                this.lookups.stores = this.lookups.stores.filter(
                    (item) => branchStores.indexOf(item.id) > -1
                );
            } else {
                this.lookups.stores = [];
            }
            /* eslint-disable prefer-destructuring */
           // this.safeTransactions = this.transactionType !== 'quotation' ? this.lookups.userSafes.data[0] || {} : {};
            // this.getAppliedTaxes();
        },
    },
};
